import React from "react"

import { Helmet } from "react-helmet";

import {Container} from "react-bootstrap"

import "../styles/Home.css"


function Impressum(){
    return (
        <>
            <Helmet>
                <title>Impressum</title>
                <meta name="description" content="Impressum der Firma Self Defined Solutions UG (haftungsbeschränkt)" />
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <Container>
                <section className="content">
                    <h1 data-aos="fade-down" data-aos-duration="2600">
                        Impressum
                    </h1>
                    <h6 data-aos="fade" data-aos-duration="2600" data-aos-delay="1000">
                        Self Defined Solutions UG (haftungsbeschränkt)
                    </h6>
                    <h6 data-aos="fade-up" data-aos-duration="2600" data-aos-delay="2000">
                        (weitere Daten folgen)
                    </h6>
                </section>
            </Container>
        </>
    )
}

export default Impressum