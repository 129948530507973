import React from 'react';

import ReactDOM from 'react-dom';

import ClientSideRoutedApp from './ClientSideRoutedApp';


ReactDOM.hydrate(
    <React.StrictMode>
        <ClientSideRoutedApp />
    </React.StrictMode>,
    document.getElementById('root')
);
