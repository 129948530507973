import React, { useEffect } from "react";

import { Switch } from "react-router-dom"

import AOS from 'aos';

import Layout from "./components/Layout"

import { Routes } from "./Routes"

import 'bootstrap/dist/css/bootstrap.css'

import 'aos/dist/aos.css'

import "./styles/globals.css"


function App() {
    useEffect(() => AOS.init(), [])

    return (
        <Layout>
            <Switch>
                <Routes />
            </Switch>
        </Layout>
    )
}

export default App