import React from 'react';

import { BrowserRouter } from "react-router-dom"

import App from "./App.js"


export default function ClientSideRoutedApp() {
    return (
        <BrowserRouter>
            <App />
        </BrowserRouter>
    )
}