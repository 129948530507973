import React, { useState } from 'react'

import { Link } from "react-router-dom"

import { isMobileOnly } from 'react-device-detect'

import { Navbar as BsNavbar, Container, Nav } from "react-bootstrap"

import { Collapse } from "reactstrap"

import { IconButton, Avatar, useScrollTrigger, Slide } from "@material-ui/core"

import { Menu } from "@material-ui/icons"


function HideOnScroll({ children }) {
    const trigger = useScrollTrigger()

    //only hide on scroll if is mobile
    if (isMobileOnly) {
        return (
            <Slide appear={false} direction="down" in={!trigger}>
                {children}
            </Slide>
        );
    }

    //TODO elevate on scroll
    return children
}

function Navbar() {
    const [navBarCollapseOpen, setNavBarCollapseOpen] = useState(false)

    const closeNavBar = () => setNavBarCollapseOpen(false)

    return (
        <HideOnScroll>
            <BsNavbar expand="lg" fixed="top" bg="light">
                <Container>
                    <BsNavbar.Brand>
                        <Link to="/">
                            <Avatar src="/logo192.png" />
                        </Link>
                    </BsNavbar.Brand>
                    <BsNavbar.Toggle style={{ border: "none" }} onClick={() => setNavBarCollapseOpen(!navBarCollapseOpen)} >
                        <IconButton>
                            <Menu />
                        </IconButton>
                    </BsNavbar.Toggle>
                    <Collapse isOpen={navBarCollapseOpen} className="navbar-collapse">
                        <Nav>
                            <Link to="/" className="nav-link" onClick={closeNavBar}>Start</Link>
                            <Link to="/dienstleistungen" className="nav-link" onClick={closeNavBar}>Dienstleistungen</Link>
                            <Link to="/kontakt" className="nav-link" onClick={closeNavBar}>Kontakt</Link>
                            <Link to="/impressum" className="nav-link" onClick={closeNavBar}>Impressum</Link>
                        </Nav>
                    </Collapse>
                </Container>
            </BsNavbar>
        </HideOnScroll>
    )
}

export default Navbar