import React from 'react';

import { Helmet } from "react-helmet";

import { Container } from "react-bootstrap"

import { List, ListItem, ListItemIcon, ListItemText, Divider } from "@material-ui/core"

import { Language, PhoneAndroid, Computer, Cloud, Memory, Router, Help } from '@material-ui/icons'

import "../styles/Home.css"


function Services() {
    const services = [
        { name: "Web-Apps", icon: <Language /> },
        { name: "Mobile-Apps", icon: <PhoneAndroid /> },
        { name: "Desktop-Apps", icon: <Computer /> },
        { name: "Cloud-APIs", icon: <Cloud /> },
        { name: "Mikrocontroller Firmware", icon: <Memory /> },
        { name: "Mikrocontroller", icon: <Memory /> },
        { name: "Sensornetzwerke", icon: <Router /> },
        { name: "Beratung", icon: <Help /> },
    ]

    return (
        <>
            <Helmet>
                <title>Unsere Dienstleistungen</title>
                <meta name="description" content="" />
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <Container>
                <section className="content">
                    <h1 data-aos="fade-down" data-aos-duration="2000">Dienstleistungen</h1>
                    <List style={{ borderRadius: 5, width: "100%", maxWidth: 480 }} className="shadow-lg" data-aos="fade-up" data-aos-duration="2000">
                        {services.map(({ name, icon }, index) => {
                            if (index + 1 === services.length) {
                                return (
                                    <ListItem key={`service-block-${index}`}>
                                        <ListItemIcon>{icon}</ListItemIcon>
                                        <ListItemText primary={name} />
                                    </ListItem>
                                )
                            }
                            return (
                                <>
                                    <ListItem key={`service-block-${index}`}>
                                        <ListItemIcon>{icon}</ListItemIcon>
                                        <ListItemText primary={name} />
                                    </ListItem>
                                    <Divider variant="middle" />
                                </>
                            )
                        })}
                    </List>
                </section>
            </Container>
        </>
    )
}

export default Services