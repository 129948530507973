import React from 'react';


function Footer(){
    return (
        <div style={{padding: 15, display: "flex", justifyContent: "center", alignItems: "center"}}>
            Self Defined Solutions UG (haftungsbeschränkt) 2021
        </div>
    )
}

export default Footer