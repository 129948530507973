import React from 'react';

import { Route } from "react-router-dom"

import Home from "./pages/index"
import Contact from "./pages/contact"
import Services from "./pages/services"
import Impressum from "./pages/impressum"


const routes = [
    { path: "/", component: Home, exact: true },
    { path: "/kontakt", component: Contact, exact: true },
    { path: "/dienstleistungen", component: Services, exact: true },
    { path: "/impressum", component: Impressum, exact: true },
]

export default routes

export function Routes() {
    return routes.map((routeProps) => (
        <Route {...routeProps} />
    ))
}