import React, { useEffect } from 'react'

import { Helmet } from "react-helmet";

import { Link } from "react-router-dom"

import { Container } from "react-bootstrap"

import { IconButton, Button, Divider, List, ListItem, ListItemText } from "@material-ui/core"

import { ExpandMore } from '@material-ui/icons'

import scrollIntoView from 'scroll-into-view'

import "../styles/Home.css"


function LandingPage({ nextTarget }) {

    const scrollToNextTarget = () => {
        scrollIntoView(document.getElementById(nextTarget), {
            time: 200,
            ease: (val) => 1 - Math.pow(1 - val, 3)
        })
    }

    return (
        <section className="content">
            <h1 className="title" data-aos="fade-down" data-aos-duration="2600">
                Flexibel
            </h1>
            <h1 className="title" data-aos="fade" data-aos-duration="2600" data-aos-delay="1000">
                Skalierbar
            </h1>
            <h1 className="title" data-aos="fade-up" data-aos-duration="2600" data-aos-delay="2000">
                Effizient
            </h1>
            <h6 style={{ marginTop: 20 }} data-aos="fade-up" data-aos-duration="2600" data-aos-delay="3000">
                individualisierte Software-, Cloud- und IoT Lösungen
            </h6>
            <IconButton data-aos="fade-up" data-aos-duration="2600" data-aos-delay="3000" onClick={scrollToNextTarget}>
                <ExpandMore />
            </IconButton>
        </section>
    )
}

function Introduction({ id, caption, description, leftAlign, nextTarget }) {

    const scrollToNextTarget = () => {
        scrollIntoView(document.getElementById(nextTarget), {
            time: 200,
            ease: (val) => 1 - Math.pow(1 - val, 3)
        })
    }

    return (
        <section id={id} className={leftAlign ? "introductionLeftAlign" : "introductionRightAlign"}>
            <h1 className="title" data-aos="fade" data-aos-duration="1500">
                {caption}
            </h1>
            <Divider flexItem />
            <List>
                {description.map((item, index) => (
                    <ListItem
                        style={{ marginBottom: 15, borderRadius: 10 }}
                        className="shadow-sm border"
                        data-aos="fade"
                        data-aos-duration="1500"
                        data-aos-delay={(index + 1) * 500}
                        button>
                        <ListItemText
                            primary={item}
                            secondary="Lesen Sie wie"
                        />
                    </ListItem>
                ))}
            </List>
            {nextTarget ? (
                <IconButton data-aos="fade" data-aos-duration="1500" data-aos-delay="1000" onClick={scrollToNextTarget}>
                    <ExpandMore />
                </IconButton>
            ) : ""}
        </section>
    )
}

function Action() {
    return (
        <section id="action" className="content">
            <h3>Benötigen Sie eine Software-, Cloud- oder IoT Lösung?</h3>
            <Link to="/kontakt">
                <Button variant="contained" >Dann Sprechen Sie mit uns!</Button>
            </Link>
        </section>
    )
}

function Home() {
    return (
        <>
            <Helmet>
                <title>Flexible Software- und IoT- Lösungen</title>
                <meta name="description" content="" />
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <Container>
                <LandingPage nextTarget="intro1" />
                <Introduction
                    id="intro1"
                    caption="Flexibel"
                    description={[
                        "weil unser Software überall läuft.",
                        "Container Lösungen sorgen für höchste Platformkompatibilität",
                        "Architekturunabhängige Lösungen, von IoT bis Datenzentren"
                    ]}
                    nextTarget="intro2" leftAlign
                />
                <Introduction
                    id="intro2"
                    caption="Skalierbar"
                    description={[
                        "weil wir auf die Cloud setzen",
                        "Sparen Sie bis zu 75% Hosting und Betriebskosten",
                        "Mehr Sicherheit, Resilienz und Leistung durch Serverless Computing"
                    ]}
                    nextTarget="intro3"
                />
                <Introduction
                    id="intro3"
                    caption="Effizient"
                    description={[
                        "weil wir auf modernste Standards setzen",
                        "Asynchrone Anfragenverarbeitung erzielt beachtliche Geschwindigkeiten",
                    ]}
                    nextTarget="action" leftAlign
                />
                <Action />
            </Container>
        </>
    )
}

export default Home