import React from "react"

import { Helmet } from "react-helmet";

import { Container } from "react-bootstrap"

import "../styles/Home.css"


function Contact() {
    return (
        <>
            <Helmet>
                <title>Kontakt</title>
                <meta name="description" content="" />
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <Container>
                <section className="content">
                    <h1 data-aos="fade-down" data-aos-duration="2600">
                        Kontakt
                    </h1>
                    <h6 data-aos="fade-up" data-aos-duration="2600" data-aos-delay="1000">
                        Email: <a href="mailto:kontakt@self-defined.de">kontakt@self-defined.de</a>
                    </h6>
                </section>
            </Container>
        </>
    )
}

export default Contact